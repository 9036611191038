@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
        font-family: 'Circular';
        src: url('/fonts/Circular_Std_Book.woff2') format('woff2'), url('/fonts/Circular_Std_Book.woff') format('woff');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'Circular';
        src: url('/fonts/Circular_Std_Bold.woff2') format('woff2'), url('/fonts/Circular_Std_Bold.woff') format('woff');
        font-weight: bold;
        font-style: normal;
    }

    body {
        font-family: Circular;
        line-height: 1.25;
        color: #373737;
    }

    .contentPage a {
        @apply underline hover:no-underline;
    }
    .contentPage a.menuLink, .menuLinks a {
        @apply no-underline hover:underline;
    }

    .contentPage p + p {
        margin-top: 1.5em;
    }

    [x-cloak] { display: none !important; }

    .inlineSVG * {
        stroke: currentColor !important;
    }
}

@layer utilities {
    .font-mini {
        @apply text-mobileSmall sm:text-mobile ld:text-small;
    }
    .font-small {
        @apply text-mobile ld:text-small;
    }
    .font-small p + p {
        margin-top: 38px;
    }
    .font-large {
        @apply text-mobile ld:text-large;
    }
    .font-menu {
        @apply text-mobile ld:text-menu;
    }
    .font-heading {
        @apply text-mobile ld:text-heading;
    }
}
